import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { EGDSHeading, EGDSHeadingSize, EGDSHeadingSizes } from "@egds/react-core/text";
import { TitleContainerProps } from "./typings";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";

const defaultSize = 3;

export const TitleContainer = withStores("context")(
  observer((props: TitleContainerProps) => {
    const { templateComponent } = props;

    /* istanbul ignore if */
    if (!templateComponent) {
      return null;
    }

    const {
      config: { hasPaddingBottom, hasInlinePadding, hasPaddingTop, title, headingSize },
    } = templateComponent;

    /* istanbul ignore next */
    if (!title) {
      return null;
    }

    /* istanbul ignore next */
    const getTitlePadding = (): EGDSSpacingProps["padding"] => {
      return {
        blockend: hasPaddingBottom ? "three" : "unset",
        inline: hasInlinePadding ? "three" : "unset",
        blockstart: hasPaddingTop ? "three" : "unset",
      };
    };

    const uitkHeadingSize =
      headingSize && EGDSHeadingSizes.has(headingSize as EGDSHeadingSize) ? headingSize : defaultSize;

    return (
      <EGDSSpacing padding={getTitlePadding()}>
        <EGDSHeading tag="h2" size={uitkHeadingSize as EGDSHeadingSize}>
          {title}
        </EGDSHeading>
      </EGDSSpacing>
    );
  })
);

export default TitleContainer;
